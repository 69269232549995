import React from "react";
import InfoContactForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";


const subscriberZendeskWorkflowGuide =  process.env.GATSBY_SUBSCRIBER_LP_ZENDESK_WORKFLOW_GUIDE_TAG;


const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h3>Improve Your Zendesk Workflow</h3>
        <h1>
          Having a strong workflow is critical to making the most of your
          Zendesk.
        </h1>
        <p>
          This free Zendesk Workflow Guide will walk you through three simple
          questions to improve your workflow so that you're making the most of
          Zendesk.
        </p>
        <p>You’ll identify:</p>
        <div class="row">
          <div class="col-lg-6">
            <div className="py-3 d-flex">
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50.828 50.828"
                width={44}
              >
                <g
                  fill="none"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  transform="translate(1.414 1.414)"
                >
                  <rect
                    width="33.941"
                    height="33.941"
                    stroke="#111"
                    rx="5"
                    transform="rotate(45 12 28.97)"
                  />
                  <path
                    stroke="#92c954"
                    d="m9.263 25.182 14.229-14 15.245 14"
                  />
                  <path stroke="#92c954" d="m12.5 29.26 11-11 12 11" />
                </g>
              </svg>
              <p class="ml-3">What Tickets are a priority for your business</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div className="py-3 d-flex">
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 46 41"
                width={44}
              >
                <g
                  fill="none"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  data-name="Group 248"
                  transform="translate(-1 -3)"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    stroke="#111"
                    data-name="Ellipse 62"
                    transform="translate(4 4)"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="square"
                    d="M21 29c-7-3.583-7-10-13-10a6 6 0 0 0-6 6v18h12V31"
                    data-name="Path 696"
                  />
                  <path
                    stroke="#92c954"
                    stroke-linecap="square"
                    d="M27 29c7-3.583 7-10 13-10a6 6 0 0 1 6 6v18H34V31"
                    data-name="Path 697"
                  />
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    stroke="#92c954"
                    data-name="Ellipse 63"
                    transform="translate(34 4)"
                  />
                </g>
              </svg>
              <p class="ml-3">How you want Agents to find Tickets</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div className="py-3 d-flex">
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 46.216 37.757"
                width={44}
              >
                <g
                  fill="none"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="2"
                >
                  <path
                    stroke="#111"
                    d="m28.867 7.068 6.349 18.689h5.5a4.5 4.5 0 0 1 4.5 4.5v3.5a3 3 0 0 1-3 3h-13L2.442 18.835a2.942 2.942 0 0 1-.683-4.092l9.144-12.8a1.867 1.867 0 0 1 2.6-.44l.013.009a1.9 1.9 0 0 1 .78 1.578 8.176 8.176 0 0 0 3.489 6.881 8.067 8.067 0 0 0 6.178 1.446"
                  />
                  <path stroke="#92c954" d="M1.216 36.757h14M1.216 28.757h5" />
                </g>
              </svg>
              <p class="ml-3">What your Agents should do with their Tickets</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6 text-center">
        <h3 className="text-white">Is your workflow working for you?</h3>
        <h3>Download our Zendesk Workflow Guide</h3>
        <div className="bg-white p-4 mt-3">
          <InfoContactForm
            location={location}
            tags={[subscriberZendeskWorkflowGuide]}
            onSubmitSuccess={()=> navigate('/lp/thank-you-zendesk-workflow-guide')}
            buttonText="DOWNLOAD WORKFLOW GUIDE" />
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>What our clients are saying regarding our Zendesk work</h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed. They are personable, caring, and reliable. I would recommend working with them, and feel confident everyone would feel the same value we did."
            name="KAYLA FISCHER"
            company="HireVue, Inc"
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Main  location={location}/>
    <Testimonial />
  </Layout>
);

export default Index;
